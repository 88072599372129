import { CalendarDateEntry } from "../../api/CalendarDateEntry"
import { useState } from 'react'
import { definitions } from "../../api/types/database"
import { useVenue } from "../../api/useVenues"
import DayDetailsSlideOver from "./DayDetailsSlideOver"


type IProps = {
  day: CalendarDateEntry
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

type DayEventItemProps = {
  event: definitions["EventItem"]
}

const DayEventItem = ({ event }: DayEventItemProps) => {
  const venueRequest = useVenue(event.venue_id);
  const [open, setOpen] = useState(false)
  const startDate = new Date(event.start_timestamp)
  const startTime = startDate.getHours();
  let startTimeDisplay = '';
  if (startTime > 12) {
    startTimeDisplay = `${startTime - 12} PM`;
  } else {
    startTimeDisplay = `${startTime} AM`;
  }

  if (venueRequest.status !== "success") {
    return <div></div>
  }
  const venue = venueRequest.data;

  return <li key={event.id}>
    <div className="group flex cursor-pointer" onClick={() => setOpen(true)}>
      <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
        {event.title} - {venue.title}
      </p>
      <time
        dateTime={event.start_timestamp}
        className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
      >
        {startTimeDisplay}
      </time>
    </div>
    <DayDetailsSlideOver isOpen={open} onClose={() => setOpen(false)} event={event} venue={venue} />
  </li>

}

export const DayDisplayLarge = ({ day }: IProps) => {
  return <div
    key={day.date.toDateString()}
    className={classNames(
      day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
      'relative py-2 px-3'
    )}
  >
    <time
      dateTime={day.date.toISOString()}
      className={
        day.isToday
          ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
          : undefined
      }
    >
      {day.date.getDate()}
    </time>
    <ol className="mt-2">
      {day.events.slice(0, 5).map((event: definitions["EventItem"]) => <DayEventItem key={event.id} event={event} />)}
      {day.events.length > 5 && <li className="text-gray-500">+ {day.events.length - 5} more</li>}
    </ol>
  </div>


}