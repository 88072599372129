import Footer from "../layout/Footer"
import { HeaderView } from "../layout/HeaderView"
import { CalendarDisplay } from "./CalendarDisplay"

export const CalendarView = () => {
  return <div>

    <div>
      <HeaderView />

      <main>
        <div className="max-w-7xl mx-auto py-18 sm:px-6 lg:px-8">
          <CalendarDisplay />
        </div>
      </main>
    </div>
    <Footer />
  </div>
}