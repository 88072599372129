import { definitions } from "../../api/types/database";
import { useVenues } from "../../api/useVenues";
import { VenueItem } from "./VenueItem";

export const VenuesList = () => {
  const venuesRequest = useVenues();

  if (venuesRequest.status === 'loading' || venuesRequest.status === 'idle') {
    return <div>Loading...</div>
  } else if (venuesRequest.status === 'error') {
    return <div>Error</div>
  }

  const venues: definitions["Venue"][] = venuesRequest.data;

  return (
    <div className='bg-white'>
      <h2 className="text-lg font-semibold text-gray-900 sticky top-0 py-4 px-4 bg-white z-10">Venues</h2>
      <div className="lg:grid lg:grid-cols-14 lg:gap-x-16">
        <ol className="mt-4 px-6 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 bg-white">

          {venues.map(venue => <VenueItem venue={venue} />)}
        </ol>
      </div>
    </div>
  )
}