export const EventItemAd = () => {
  return (
    <li className="relative flex space-x-6 py-6">

      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5446246952756663"
        crossOrigin="anonymous"></script>
      <ins className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-format="fluid"
        data-ad-layout-key="-fb+5w+4e-db+86"
        data-ad-client="ca-pub-5446246952756663"
        data-ad-slot="8386773202"></ins>
      <script>
        (adsbygoogle = window.adsbygoogle || []).push({ });
      </script>
    </li>
  )
}