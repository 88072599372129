/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link, NavLink } from 'react-router-dom';

const navigation = [
  { name: 'Home', href: '/', current: true },
  { name: 'Calendar', href: '/calendar', current: false },
  { name: 'Venues', href: '/venues', current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export function HeaderView() {
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <a href='/'>
                    <img
                      className="hidden md:block lg:block h-12 w-auto mr-3"
                      src="/logo.png"
                      alt="Heart-shaped LGBTQIA+ Flag"
                    />
                  </a>
                  <a href='/'>
                    <span
                      className="block w-auto font-bold text-white "
                    >Gay Nightlife &amp; Events</span>
                  </a>
                </div>
                <div className="hidden sm:block sm:ml-6 mt-1">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <NavLink to={item.href}
                        key={item.href}
                        className={({ isActive }: any) => classNames(
                          isActive
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )
                        }
                      >{item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <img
                  className="block lg:hidden md:hidden h-12 w-auto"
                  src="/logo.png"
                  alt="Heart-shaped LGBTQIA+ Flag"
                />

              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <NavLink to={item.href}
                  key={item.href}
                  className={({ isActive }: any) => classNames(
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )
                  }
                >
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                  >
                    {item.name}
                  </Disclosure.Button>
                </NavLink>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
