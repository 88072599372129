import { definitions } from "../../api/types/database"
import { Link } from "react-router-dom";
import { ExternalLinkIcon, LocationMarkerIcon } from '@heroicons/react/outline'


type IProps = {
  venue: definitions["Venue"];
}
export const VenueItem = ({ venue }: IProps) => {
  const combinedAddress = `${[venue.address, venue.address2].join(' ')} ${venue.city}, ${venue.state} ${venue.postal_code}`;
  const mapLink = `https://maps.google.com/?q=${combinedAddress}`
  return <li
    key={venue.id}
    className="bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600"
  >

    <div className="mt-1">
      <div className="flex space-x-3">
        <div className="flex-1 overflow-hidden">
          <div className="basis-full">
            <div className="flex justify-between flex-wrap">
              <div className="overflow-hidden mb-3">

                <h1 className="text-gray-900 truncate hover:underline text-2xl">
                  <Link to={`/venues/${venue.id}`}>{venue.title}</Link></h1>
                <h2 className="text-gray-500 truncate hover:underline">
                  <a target='_blank' rel='noreferrer' href={mapLink}>
                    <div className="w-4 align-text-top inline-block"><LocationMarkerIcon /></div> {combinedAddress}</a>
                </h2>
              </div>
              <div className="mb-3">
                <div
                  className="text-sm text-gray-500 hover:underline">
                  {venue.website ? <a href={venue.website} target='_blank' rel='noreferrer'>Website <div className="w-4 align-top inline-block"><ExternalLinkIcon /></div></a> : ''}
                </div>
              </div>
            </div>

          </div>
          <div className="basis-full">
            {venue.description}
          </div>
        </div>
      </div>
    </div>
  </li>

}
