

// export const CalendarDisplay = () => {
/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid'
import { CalendarDateEntry } from '../../api/CalendarDateEntry';
import { DayDisplayLarge } from './DayDisplay';
import { definitions } from '../../api/types/database';
import { useEventItems } from '../../api/useEventItems';

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

function getDaysInMonth(month: number, year: number) {
  let date = new Date(year, month, 1);
  if (date.getDay() > 0) {
    date.setDate(date.getDate() - date.getDay());
  }
  let days = [];
  while (date.getMonth() <= month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  while (date.getDay() > 0) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return days;
}

const getDaysForCalendar = (startDate: Date) => {
  const today = new Date();
  const dates = getDaysInMonth(startDate.getMonth(), startDate.getFullYear());
  let days: CalendarDateEntry[] = dates.map(date => {
    return {
      date: date,
      isSelected: false,
      isCurrentMonth: date.getMonth() === startDate.getMonth(),
      isToday: today.getDate() === date.getDate() && today.getMonth() === date.getMonth(),
      events: []
    } as CalendarDateEntry
  });
  return days;
}

export const CalendarDisplay = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [calendarDays, setCalendarDays] = useState<CalendarDateEntry[]>(getDaysForCalendar(selectedDate));
  // const today = new Date();

  const eventEntriesRequest = useEventItems();

  useEffect(() => {
    if (selectedDate && eventEntriesRequest.data) {
      let days = getDaysForCalendar(selectedDate);
      eventEntriesRequest.data.forEach((item: definitions["EventItem"]) => {
        let startDate = new Date(item.start_timestamp)
        let day = days.filter(d => d.date.getDate() === startDate.getDate() && d.date.getMonth() === startDate.getMonth() && d.date.getFullYear() === startDate.getFullYear())[0];
        if (day) {
          day.events.push(item);
        }
      });
      setCalendarDays(days);
    }
  }, [selectedDate, eventEntriesRequest.data]);

  const adjustSelectedDate = (value: number) => {
    let date = new Date(selectedDate);
    date.setMonth(date.getMonth() + value);
    setSelectedDate(date);
  }

  if (eventEntriesRequest.status !== 'success') {
    return <div>Loading...</div>
  }

  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      <header className="relative z-20 flex items-center justify-between border-b border-gray-200 py-4 lg:flex-none">
        <h1 className="text-lg font-semibold text-gray-900">
          <time dateTime="2022-01">{monthNames[selectedDate.getMonth()]} {selectedDate.getFullYear()}</time>
        </h1>
        <div className="flex items-center">
          <div className="flex items-center rounded-md shadow-sm md:items-stretch">
            <button
              type="button"
              onClick={() => adjustSelectedDate(-1)}
              className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={() => setSelectedDate(new Date())}
              className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
            >
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              onClick={() => adjustSelectedDate(1)}

              className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </header>
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">un</span>
          </div>
          <div className="bg-white py-2">
            M<span className="sr-only sm:not-sr-only">on</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-2">
            W<span className="sr-only sm:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-2">
            F<span className="sr-only sm:not-sr-only">ri</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">at</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
            {calendarDays.map((day) => (<DayDisplayLarge key={day.date.toISOString()} day={day} />))}
          </div>
          <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
            {calendarDays.map((day) => (
              <button
                key={day.date.toISOString()}
                type="button"
                className={classNames(
                  day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                  (day.isSelected || day.isToday) && 'font-semibold',
                  day.isSelected && 'text-white',
                  !day.isSelected && day.isToday && 'text-indigo-600',
                  !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                  !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                  'flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10'
                )}
              >
                <time
                  dateTime={day.date.toISOString()}
                  className={classNames(
                    day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                    day.isSelected && day.isToday && 'bg-indigo-600',
                    day.isSelected && !day.isToday && 'bg-gray-900',
                    'ml-auto'
                  )}
                >
                  {day.date.getDate()}
                </time>
                <p className="sr-only">{day.events.length} events</p>
                {day.events.length > 0 && (
                  <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    {/* {day.events.map((event) => (
                      <div key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                    ))} */}
                  </div>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
      {/* {selectedDay?.events && selectedDay?.events.length > 0 && (
        <div className="py-10 px-4 sm:px-6 lg:hidden">
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {selectedDay?.events?.map((event) => (
              <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                <div className="flex-auto">
                  <p className="font-semibold text-gray-900">{event.name}</p>
                  <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                    <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {event.time}
                  </time>
                </div>
                <a
                  href={event.href}
                  className="ml-6 flex-none self-center rounded-md border border-gray-300 bg-white py-2 px-3 font-semibold text-gray-700 opacity-0 shadow-sm hover:bg-gray-50 focus:opacity-100 group-hover:opacity-100"
                >
                  Edit<span className="sr-only">, {event.name}</span>
                </a>
              </li>
            ))}
          </ol>
        </div>
      )} */}
    </div>
  )
}
