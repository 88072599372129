import { useQuery } from "react-query"
import { supabase } from "./supabase"
import { definitions } from "./types/database"

const handleSupabaseError = (props: any) => {
  if (props?.error) {
    throw props.error;
  }
  return props;
}


export const useEventItems = () => {
  return useQuery(['eventItems'], async () => await supabase.from<definitions["EventItem"]>("EventItem")
  .select("*")
  .then(handleSupabaseError)
  .then(({ data }) => data))
}

export const useEventItemsForDate = (date: Date) => {
  let oneDayAhead = new Date();
  oneDayAhead.setHours(0, 0, 0, 0);
  oneDayAhead.setDate(date.getDate() + 1);

  const cacheKey = date.toISOString();
  return useQuery(['eventItemsForDate', cacheKey], async () => await supabase.from<definitions["EventItem"]>("EventItem")
  .select("*")
  .gte("start_timestamp", date.toISOString())
  .lt("start_timestamp", oneDayAhead.toISOString())
  .order('start_timestamp', {ascending: true})
  .then(handleSupabaseError)
  .then(({ data }) => data))
}

export const useEventItemsComingSoon = (date: Date) => {
  let oneDayAhead = new Date();
  oneDayAhead.setHours(0, 0, 0, 0);
  oneDayAhead.setDate(date.getDate() + 7);

  const cacheKey = date.toISOString();
  return useQuery(['eventItemsComingSoon', cacheKey], async () => await supabase.from<definitions["EventItem"]>("EventItem")
  .select("*")
  .gte("start_timestamp", date.toISOString())
  .lt("start_timestamp", oneDayAhead.toISOString())
  .order('start_timestamp', {ascending: true})
  .limit(5)
  .then(handleSupabaseError)
  .then(({ data }) => data))
}

export const useEventItemsForVenue = (venueId: number, date: Date) => {
    return useQuery(['useEventItemsForVenue', venueId, date.toISOString()], async () => await supabase.from<definitions["EventItem"]>("EventItem")
  .select("*")
  .eq("venue_id", venueId)
    .gte("start_timestamp", date.toISOString())
  .order('start_timestamp', {ascending: true})
  .then(handleSupabaseError)
  .then(({ data }) => data))

}

