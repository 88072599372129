const options: Intl.DateTimeFormatOptions = { dateStyle: 'full', timeStyle: 'short' };
const formatter = new Intl.DateTimeFormat('en-US', options);

export const useFormattedTimestamp = (value: string) => {
  const parsedTimestamp = Date.parse(value)
  return formatter.format(parsedTimestamp);
};

const optionsTimeOnly: Intl.DateTimeFormatOptions = { timeStyle: 'short' };
const formatterTimeOnly = new Intl.DateTimeFormat('en-US', optionsTimeOnly);

export const useFormattedTimeOnly = (value: string) => {
  const parsedTimestamp = Date.parse(value)
  return formatterTimeOnly.format(parsedTimestamp);
};


const dateOptions: Intl.DateTimeFormatOptions = { dateStyle: 'full' };
const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);

export const useFormattedDate = (value: string) => {
  const parsedTimestamp = Date.parse(value)
  return dateFormatter.format(parsedTimestamp);
};

export const getFormattedDate = (value: string) => {
  const parsedTimestamp = Date.parse(value)
  return dateFormatter.format(parsedTimestamp);

}

export const getFormattedTimestamp = (value: string) => {
  const parsedTimestamp = Date.parse(value)
  return formatter.format(parsedTimestamp);
};


export const getFormattedTimeOnly = (value: string) => {
  const parsedTimestamp = Date.parse(value)
  return formatterTimeOnly.format(parsedTimestamp);
};
