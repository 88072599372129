export const SquareAdUnit = () => {
  return (
    <div>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5446246952756663"
        crossOrigin="anonymous"></script>
      <ins className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-5446246952756663"
        data-ad-slot="6735614335"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
      <script>
        (adsbygoogle = window.adsbygoogle || []).push({ });
      </script>
    </div>
  )
}