import { definitions } from "../../api/types/database";
import { useVenue } from "../../api/useVenues"

type IProps = {
  venueId: number;
}
export const VenueLabel = ({ venueId }: IProps) => {
  const venueRequest = useVenue(venueId);

  if (venueRequest.status === 'loading' || venueRequest.status === 'idle') {
    return <span></span>
  }

  const venue: definitions["Venue"] = venueRequest.data;
  return <span>{venue.title}</span>

}