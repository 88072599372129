import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { VenuesView } from './components/Venues/VenuesView';
import { CalendarView } from './components/Calendar/CalendarView';
import Home from './components/Home/Home';
import './index.css';
import { Venue } from './components/Venues/Venue';

const queryClient = new QueryClient();


ReactDOM.render(
  <React.StrictMode>

    <QueryClientProvider client={queryClient}>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/venues" element={<VenuesView />} />
          <Route path="/venues/:id" element={<Venue />} />
          <Route path="/calendar" element={<CalendarView />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);