import { UseQueryResult } from 'react-query';
import { CalendarDateEntry } from '../../api/CalendarDateEntry';
import { definitions } from '../../api/types/database';

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export function getDaysInMonth(month: number, year: number) {
  let date = new Date(year, month, 1);
  if (date.getDay() > 0) {
    date.setDate(date.getDate() - date.getDay());
  }
  let days = [];
  while (date.getMonth() <= month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  while (date.getDay() > 0) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return days;
}

export const getDaysForCalendar = (startDate: Date) => {
  const today = new Date();
  const dates = getDaysInMonth(startDate.getMonth(), startDate.getFullYear());
  let days: CalendarDateEntry[] = dates.map(date => {
    return {
      date: date,
      isSelected: false,
      isCurrentMonth: date.getMonth() === startDate.getMonth(),
      isToday: today.getDate() === date.getDate() && today.getMonth() === date.getMonth(),
      events: []
    } as CalendarDateEntry
  });
  return days;
}

export const useCalendarContent = (selectedDate: Date, eventEntriesRequest: UseQueryResult) => {
  let calendarDays = getDaysForCalendar(selectedDate);
  if (eventEntriesRequest.status === 'success' && selectedDate ) {
      const eventEntries = eventEntriesRequest.data as definitions["EventItem"][];
      eventEntries.forEach((item: definitions["EventItem"]) => {
        let startDate = new Date(item.start_timestamp)
        let day = calendarDays.filter(d => d.date.getDate() === startDate.getDate() && d.date.getMonth() === startDate.getMonth() && d.date.getFullYear() === startDate.getFullYear())[0];
        if (day) {
          day.events.push(item);
        }
      });
  }
  return calendarDays;
}

export function filterEventsByDate(item: CalendarDateEntry, inputDate: Date): boolean {
  return item.date.getDate() === inputDate.getDate() && item.date.getMonth() === inputDate.getMonth() && item.date.getFullYear() === inputDate.getFullYear()
}

export function dateIsEqual(dateA: Date, dateB: Date): boolean {
  if (dateA.getDate() === dateB.getDate() && dateA.getMonth() === dateB.getMonth() && dateA.getFullYear() === dateB.getFullYear()) {
    return true;
  } else {
    return false;
  }

}