import { useQuery } from "react-query"
import { supabase } from "./supabase"
import { definitions } from "./types/database"

const handleSupabaseError = (props: any) => {
  if (props?.error) {
    throw props.error;
  }
  return props;
}


export const useVenues = () => {
  return useQuery(['venues'], async () => await supabase.from<definitions["Venue"]>("Venue")
  .select("*")
  .then(handleSupabaseError)
  .then(({ data }) => data))
}

export const useVenue = (id: number) => {
  return useQuery(['venues', id], async () => await supabase.from<definitions["Venue"]>("Venue")
  .select("*")
  .eq('id', id)
  .limit(1)
  .then(handleSupabaseError)
  .then(({ data }) => data[0]))
}