/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { definitions } from '../../api/types/database'
import { Link } from 'react-router-dom'
import { VenueLabel } from '../Venues/VenueLabel'
import { getFormattedTimeOnly, getFormattedTimestamp } from '../common/useFormattedTimestamp'
import ReactLinkify from 'react-linkify'
import { useHolderjs } from 'use-holderjs'
import ICalendarLink from "react-icalendar-link";


type IProps = {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  event: definitions["EventItem"];
  venue: definitions["Venue"];
}
const componentDecorator = (decoratedHref: string, decoratedText: string, key: number): React.ReactNode => {
  return <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">{decoratedText}</a>;
}


export default function DayDetailsSlideOver({ isOpen, onClose, event, venue }: IProps) {
  useHolderjs();
  const eventImageUrl = event.image_url ? event.image_url : 'holder.js/300x200'

  const eventImage = <div className="max-w-md pb-4"><img src={eventImageUrl} alt="" /></div>;
  const venueLocation = `${venue.title} ${[venue.address, venue.address2].join(" ")} ${venue.city}, ${venue.state} ${venue.postal_code}}`;
  const iCalEvent = {
    title: event.title,
    description: event.description,
    startTime: event.start_timestamp,
    endTime: event.end_timestamp,
    location: venueLocation,
    attendees: []
  }



  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-50" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <div className="flec-col">

                        <Dialog.Title className="text-lg font-medium text-gray-900">{event.title}</Dialog.Title>
                        <h4 className='hover:underline'>
                          <Link to={`/venues/${event.venue_id}`}><VenueLabel venueId={event.venue_id} /></Link>

                        </h4>
                        <h5>
                          <time
                            dateTime={`${getFormattedTimestamp(event.start_timestamp)} - ${getFormattedTimeOnly(event.end_timestamp)}`}
                            className="text-sm text-gray-500">
                            {getFormattedTimestamp(event.start_timestamp)} - {getFormattedTimeOnly(event.end_timestamp)}
                          </time>
                        </h5>

                      </div>

                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => onClose(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    {/* Replace with your content */}
                    <div className="absolute inset-0 px-4 sm:px-6">
                      {eventImage}
                      <p className="text-sm text-gray-500">
                        <ReactLinkify componentDecorator={componentDecorator}>
                          {event.description}
                        </ReactLinkify>
                      </p>
                      <div className="flex py-6">
                        <ICalendarLink
                          // @ts-ignore
                          event={iCalEvent}
                          className="flex-1 items-center text-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Add to Calendar...
                        </ICalendarLink>
                      </div>
                    </div>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
