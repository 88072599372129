/* This example requires Tailwind CSS v2.0+ */
import NewEventItemsListing from '../EventItem/NewEventItemsListing'
import Footer from '../layout/Footer'
import { HeaderView } from '../layout/HeaderView'

export default function Home() {
  return (
    <div>

      <div>
        <HeaderView />
        <div className="flex py-24 bg-cover bg-center bg-hero-image-sf">
          <div className='m-auto text-center text-3xl whitespace-nowrap font-bold text-white'>
            San Francisco, CA
          </div>
        </div>

        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-8 lg:mx-36 py-6 sm:px-0 bg-white">
              <NewEventItemsListing />
            </div>
          </div>
        </main>

      </div>
      <Footer />
    </div>
  )
}
