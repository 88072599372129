/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid'
import { useEventItems } from '../../api/useEventItems'
import { dateIsEqual, filterEventsByDate, monthNames, useCalendarContent } from '../common/useCalendarContent'
import { definitions } from '../../api/types/database'
import { getFormattedDate } from '../common/useFormattedTimestamp'
import { NewEventItem } from './NewEventItem'
import { EventItemAd } from '../Ads/EventItemAd'
import { SquareAdUnit } from '../Ads/SquareAdUnit'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function NewEventItemsListing() {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const eventEntriesRequest = useEventItems();

  const calendarContent = useCalendarContent(selectedDate, eventEntriesRequest);

  const adjustSelectedDate = (value: number) => {
    let date = new Date(selectedDate);
    date.setMonth(date.getMonth() + value);
    setSelectedDate(date);
  }


  return (
    <div className='bg-white'>
      <h2 className="text-lg font-semibold text-gray-900 sticky top-0 py-4 px-4 bg-white z-10 text-center md:text-left"><span className='hidden md:inline-block'>Upcoming events for&nbsp;</span>{getFormattedDate(selectedDate.toISOString())}</h2>
      <div className="lg:grid lg:grid-cols-14 lg:gap-x-16">
        <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 mr-6">
          <div className="flex items-center text-gray-900">
            <button
              type="button"
              onClick={() => adjustSelectedDate(-1)}

              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto font-semibold">{monthNames[selectedDate.getMonth()]}</div>
            <button
              type="button"
              onClick={() => adjustSelectedDate(1)}

              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {calendarContent.map((day, dayIdx) => (
              <button
                onClick={() => setSelectedDate(day.date)}
                key={day.date.toISOString()}
                type="button"
                className={classNames(
                  'py-1.5 hover:bg-gray-100 focus:z-10',
                  day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                  (dateIsEqual(day.date, selectedDate) || day.isToday) && 'font-semibold',
                  dateIsEqual(day.date, selectedDate) && 'text-white',
                  !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                  !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                  day.isToday && !dateIsEqual(day.date, selectedDate) && 'text-indigo-600',
                  dayIdx === 0 && 'rounded-tl-lg',
                  dayIdx === 6 && 'rounded-tr-lg',
                  dayIdx === calendarContent.length - 7 && 'rounded-bl-lg',
                  dayIdx === calendarContent.length - 1 && 'rounded-br-lg'
                )}
              >
                <time
                  dateTime={day.date.toDateString()}
                  className={classNames(
                    'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                    dateIsEqual(day.date, selectedDate) && day.isToday && 'bg-indigo-600',
                    dateIsEqual(day.date, selectedDate) && !day.isToday && 'bg-gray-900'
                  )}
                >
                  {day.date.getDate()}
                </time>
              </button>
            ))}
          </div>

          <SquareAdUnit />
        </div>
        <ol className="mt-4 px-6 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 bg-white">
          {calendarContent.filter((item) => filterEventsByDate(item, selectedDate))[0].events.map((eventItem: definitions["EventItem"]) => (
            <NewEventItem eventItem={eventItem} key={eventItem.id} />
          ))
          }
          <EventItemAd key="ad-item" />
        </ol >
      </div >
    </div >
  )
}
