import { useParams } from "react-router-dom"
import { definitions } from "../../api/types/database";
import { useEventItemsForVenue } from "../../api/useEventItems";
import { useVenue } from "../../api/useVenues";
import Footer from "../layout/Footer"
import { HeaderView } from "../layout/HeaderView"
import { VenueItem } from "./VenueItem";
import { NewEventItem } from "../EventItem/NewEventItem";


export const Venue = () => {
  const { id } = useParams();
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  const venueRequest = useVenue(Number(id));
  const eventItemsRequest = useEventItemsForVenue(Number(id), today)

  if (venueRequest.status === 'loading' || venueRequest.status === 'idle' || eventItemsRequest.status === 'loading' || eventItemsRequest.status === 'idle') {
    return <div>Loading...</div>
  } else if (venueRequest.status === 'error' || eventItemsRequest.status === 'error') {
    return <div>Error</div>
  }
  const venue: definitions["Venue"] = venueRequest.data;
  const eventItems: definitions["EventItem"][] = eventItemsRequest.data;

  return <div>
    <div>
      <HeaderView />
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-8 lg:mx-36 py-6 sm:px-0 bg-white">
            <ol className="mt-4 px-6 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 bg-white">

              <VenueItem venue={venue} />
            </ol>
            <ol className="mt-4 px-6 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 bg-white">
              {eventItems.map((eventItem: definitions["EventItem"]) => (
                <NewEventItem eventItem={eventItem} />
              ))}

            </ol>
          </div>
        </div>
      </main>

    </div>
    <Footer />
  </div>
}