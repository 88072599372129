import {
  CalendarIcon,
  LocationMarkerIcon,
  ExternalLinkIcon
} from '@heroicons/react/solid'

import { Link } from "react-router-dom"
import { definitions } from "../../api/types/database"
import { useVenue } from '../../api/useVenues'
import { getFormattedDate, getFormattedTimeOnly } from "../common/useFormattedTimestamp"
import { VenueLabel } from "../Venues/VenueLabel"
import ICalendarLink from "react-icalendar-link";
import ReactLinkify from 'react-linkify'

type IProps = {
  eventItem: definitions["EventItem"];
}
const componentDecorator = (decoratedHref: string, decoratedText: string, key: number): React.ReactNode => {
  return <a
    href={decoratedHref}
    key={key}
    target="_blank"
    rel="noreferrer"
    className='hover:underline'
  ><ExternalLinkIcon className='inline-block w-4 h-4' /> More...</a>;
}


export const NewEventItem = ({ eventItem }: IProps) => {
  const venueRequest = useVenue(eventItem.venue_id);
  if (venueRequest.status !== 'success') {
    return <div></div>
  }
  const venue = venueRequest.data

  const venueLocation = `${venue.title} ${[venue.address, venue.address2].join(" ")} ${venue.city}, ${venue.state} ${venue.postal_code}}`;
  const iCalEvent = {
    title: eventItem.title,
    description: eventItem.description,
    startTime: eventItem.start_timestamp,
    endTime: eventItem.end_timestamp,
    location: venueLocation,
    attendees: []
  }


  return (
    <li key={eventItem.id} className="relative flex space-x-6 py-6">
      <img src={eventItem.image_url} alt="" className="h-14 w-14 flex-none rounded-full" />
      <div className="flex-auto">
        <h3 className="pr-10 font-semibold text-gray-900">{eventItem.title}</h3>
        <dl className="mt-2 flex flex-col text-gray-500">
          <div className="flex items-start space-x-3">
            <dt className="mt-0.5">
              <span className="sr-only">Date</span>
              <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>
              <ICalendarLink
                event={iCalEvent}
                className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600 hover:underline"
              >

                <time dateTime={eventItem.start_timestamp}>
                  {getFormattedDate(eventItem.start_timestamp)} at {getFormattedTimeOnly(eventItem.start_timestamp)}
                </time>
              </ICalendarLink>
            </dd>
          </div>
          <div className="mt-2 flex items-start space-x-3 hover:underline">
            <dt className="mt-0.5">
              <span className="sr-only">Location</span>
              <LocationMarkerIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd><Link to={`/venues/${eventItem.venue_id}`}>
              <VenueLabel venueId={eventItem.venue_id} />
            </Link>
            </dd>

          </div>
          <div className="whitespace-pre-line text-ellipsis">

            <ReactLinkify componentDecorator={componentDecorator}>
              {eventItem.description}
            </ReactLinkify>
          </div>
        </dl>
      </div>
      <div className="absolute top-6 right-0">
      </div>
    </li>
  )
}